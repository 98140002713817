<template>
  <div>
    <div class="row text-left px-5 mt-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Packing list File (PDF)</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        <a :href="package_detail.package_list" target="_blank">View</a>
      </div>
    </div>
    <hr />

    <div class="row text-left px-5">
      <div class="col-sm-3">
        <h6 class="mb-0">Invoices user file (PDF)</h6>
      </div>
      <div class="col-sm-9 text-secondary" v-if="package_detail">
        <a :href="package_detail.invoice" target="_blank">View</a>
      </div>
    </div>
    <hr />

    <div
      v-if="package_invoice && listDetailPackage.status_invoice !== 'waiting'"
    >
      <div v-if="package_invoice.length !== 0">
        <div class="row text-left px-5">
          <div class="col-sm-12">
            <h6>Invoices admin</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                  <th>Price Per CBM</th>
                  <th>Total</th>
                </thead>
                <tr v-for="(inv, i) in package_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                  <td>{{ inv.price_per_cbm }}</td>
                  <td>{{ inv.total }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="not_match_invoice">
      <div
        v-if="
          not_match_invoice.length !== 0 &&
          listDetailPackage.status_checking_warehouse == 1
        "
      >
        <div class="row text-left px-5">
          <div class="col-sm-12">
            <h6>Not Match Result</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                </thead>
                <tr v-for="(inv, i) in not_match_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-end"
          v-if="
            listDetailPackage.status_invoice !== 'added_invoice' &&
            listDetailPackage.status_checking == 'not-match'
          "
        >
          <button
            class="btn btn-primary px-4 mx-1"
            v-on:click="createNewInvoices()"
          >
            Create New Invoice
          </button>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="revised_invoice">
      <div v-if="revised_invoice.length !== 0">
        <div class="row text-left px-5">
          <div class="col-sm-12">
            <h6>Revised Invoice</h6>
          </div>
          <div class="col-sm-12">
            <div class="w-100">
              <table class="table table-bordered w-100">
                <thead>
                  <th>Description</th>
                  <th>Carton</th>
                  <th>Cbm</th>
                  <th>Price Per CBM</th>
                  <th>Total</th>
                </thead>
                <tr v-for="(inv, i) in revised_invoice" :key="i">
                  <td>{{ inv.description }}</td>
                  <td>{{ inv.carton }}</td>
                  <td>{{ inv.cbm }}</td>
                  <td>{{ inv.price_per_cbm }}</td>
                  <td>{{ inv.total }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="payment_proof">
      <div v-if="payment_proof.length !== 0">
        <div class="row text-left px-5">
          <div class="col-sm-3">
            <h6 class="mb-0">Proof of payment user</h6>
          </div>
          <div class="col-sm-9 text-secondary">
            <div v-for="(proof, i) in payment_proof" :key="i">
              <a
                :href="proof.image"
                target="_blank"
                v-if="proof.type !== 'pay_in_indonesia'"
                >View - {{ proof.description }}
              </a>
              <p v-if="proof.type == 'pay_in_indonesia'">Pay in Indonesia</p>

              <div
                class="d-flex justify-content-end mt-2"
                v-if="
                  listDetailPackage.status_payment !== 'paid' &&
                  proof.type == 'full_payment'
                "
              >
                <button
                  class="btn btn-primary px-4 mx-1 mt-1"
                  v-on:click="
                    changeStatusPayment(
                      listDetailPackage.id,
                      'approve-full-payment'
                    )
                  "
                >
                  Approve Payment and send package
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="package_detail.tracking_number">
      <div class="row text-left px-5">
        <div class="col-sm-3">
          <h6 class="mb-0">Tracking Number</h6>
        </div>
        <div class="col-sm-9 text-secondary">
          <p>{{ package_detail.tracking_number }}</p>
        </div>
      </div>
      <hr />
    </div>

    <div v-if="delivery_proof">
      <div v-if="delivery_proof.length != 0">
        <div class="row text-left px-5">
          <div class="col-sm-3">
            <h6 class="mb-0">Picture Delivery</h6>
          </div>
          <div v-for="(delv, i) in delivery_proof" :key="i">
            <a :href="delv.image_1" target="_blank">View File 1</a> -
            <a :href="delv.image_2" target="_blank">View File 2</a>
            <p target="_blank">Recipient Name: {{ delv.recipient_name }}</p>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div v-if="payment_proof && !showWarehouseAddress">
      <payment-approval
        :listDetailPackage="listDetailPackage"
        :changeStatusPayment="changeStatusPayment"
      ></payment-approval>
    </div>

    <package-approval
      :listDetailPackage="listDetailPackage"
      :changeStatusPackages="changeStatusPackages"
    ></package-approval>
  </div>
</template>

<script>
import PackageApproval from "./action-button/package-approval.vue";
import paymentApproval from "./action-button/payment-approval.vue";

export default {
  props: [
    "package_detail",
    "package_invoice",
    "not_match_invoice",
    "revised_invoice",
    "listDetailPackage",
    "payment_proof",
    "delivery_proof",
    "showWarehouseAddress",
    "changeStatusPackages",
    "changeStatusPayment",
    "createNewInvoices",
    "new_invoices",
    "isFullPayment",
  ],
  name: "CustomerPackageDetailOther",
  components: { PackageApproval, paymentApproval },
  mounted() {
    console.log(this.package_detail);
  },
};
</script>

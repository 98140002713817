<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Packages Detail</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/admin/customer-packages"
                >Customer Packages</router-link
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Packages Detail
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div v-if="loading">
      <loader-component></loader-component>
    </div>
    <div v-if="!loading">
      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card shadow">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center text-center">
                <img
                  src="https://bootdey.com/img/Content/avatar/avatar7.png"
                  alt="Admin"
                  class="rounded-circle"
                  width="150"
                />
                <div class="mt-3">
                  <h4 v-if="customer_detail">
                    {{ customer_detail.name }}
                  </h4>
                  <p class="text-secondary mb-1" v-if="customer_detail">
                    {{ customer_detail.email }}
                  </p>
                  <p class="text-muted font-size-sm" v-if="customer_detail">
                    {{ customer_detail.address }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-3 shadow">
            <div class="card-body">
              <ul
                class="nav nav-pills d-flex justify-content-around mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active px-5"
                    id="pills-customer-profile-tab"
                    data-toggle="pill"
                    href="#pills-customer-profile"
                    role="tab"
                    aria-controls="pills-customer-profile"
                    aria-selected="true"
                    >Customer Profile</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link px-5"
                    id="pills-customer-packages-tab"
                    data-toggle="pill"
                    href="#pills-customer-packages"
                    role="tab"
                    aria-controls="pills-customer-packages"
                    aria-selected="false"
                    >Customer Packages</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link px-5"
                    id="pills-other-tab"
                    data-toggle="pill"
                    href="#pills-other"
                    role="tab"
                    aria-controls="pills-other"
                    aria-selected="false"
                    >Other</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-customer-profile"
                  role="tabpanel"
                  aria-labelledby="pills-customer-profile-tab"
                >
                  <customer-profile
                    :customer_detail="customer_detail"
                  ></customer-profile>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-customer-packages"
                  role="tabpanel"
                  aria-labelledby="pills-customer-packages-tab"
                >
                  <customer-package
                    :listDetailPackage="listDetailPackage"
                    :package_detail="package_detail"
                    :getDetailPackage="getDetailPackage"
                  ></customer-package>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-other"
                  role="tabpanel"
                  aria-labelledby="pills-other-tab"
                >
                  <other-package
                    :package_detail="package_detail"
                    :package_invoice="package_invoice"
                    :not_match_invoice="not_match_invoice"
                    :revised_invoice="revised_invoice"
                    :listDetailPackage="listDetailPackage"
                    :delivery_proof="delivery_proof"
                    :payment_proof="payment_proof"
                    :changeStatusPackages="changeStatusPackages"
                    :changeStatusPayment="changeStatusPayment"
                    :showWarehouseAddress="showWarehouseAddress"
                    :createNewInvoices="createNewInvoices"
                    :new_invoices="new_invoices"
                    :new_revised_invoices="new_revised_invoices"
                    :isFullPayment="isFullPayment"
                  ></other-package>

                  <div class="row" v-if="showWarehouseAddress">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Warehouse Address</label>
                        <select
                          class="form-control"
                          v-model="form.warehouse_address"
                        >
                          <option
                            v-for="(adrs, a) in address"
                            :key="a"
                            :value="adrs.warehouse_address"
                          >
                            {{ adrs.title }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="">Additional Info</label>
                        <input
                          type="text"
                          name="additional_info"
                          id=""
                          class="form-control"
                          v-model="form.additional_info"
                        />
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-primary px-4"
                          @click.prevent="
                            submitAddress(
                              listDetailPackage.id,
                              'approve-down-payment'
                            )
                          "
                        >
                          Send Warehouse Address
                        </button>
                        <button
                          class="btn btn-danger px-4 ml-3"
                          @click.prevent="
                            showWarehouseAddress = !showWarehouseAddress
                          "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="inv"
        ref="inv"
        class="mt-5"
        v-if="
          listDetailPackage.status_approval == 'approved' &&
          listDetailPackage.status_invoice == 'waiting'
        "
      >
        <create-invoice
          :package_id="id"
          :sendNotifyInvoices="sendNotifyInvoices"
        ></create-invoice>
      </div>

      <div
        id="inv"
        ref="inv"
        class="mt-5"
        v-if="
          listDetailPackage.status_checking == 'not-match' &&
          listDetailPackage.status_invoice == 'first_invoice' &&
          new_invoices == true
        "
      >
        <create-new-invoice
          :package_id="id"
          :sendNotifyNewInvoices="sendNotifyNewInvoices"
        ></create-new-invoice>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import axios from "axios";
import LoaderComponent from "../../components/Loader.vue";
import CustomerProfile from "../../components/admin/detail-package/customer-profile.vue";
import CustomerPackage from "../../components/admin/detail-package/customer-package.vue";
import OtherPackage from "../../components/admin/detail-package/other-package.vue";
import CreateInvoice from "../../components/admin/detail-package/create-invoice.vue";
import CreateNewInvoice from "../../components/admin/detail-package/create-new-invoice.vue";
export default {
  props: ["code", "id"],
  name: "CustomerPackagesDetail",
  components: {
    LoaderComponent,
    CustomerProfile,
    CustomerPackage,
    OtherPackage,
    CreateInvoice,
    CreateNewInvoice,
  },
  data() {
    return {
      address: "",
      loading: true,
      isEdit: false,
      invoices: "",
      showInv: false,
      listDetailPackage: {},
      package_detail: {},
      package_invoice: {},
      not_match_invoice: {},
      revised_invoice: {},
      customer_detail: {},
      payment_proof: {},
      delivery_proof: {},
      is_jakarta: "",
      new_invoices: false,
      new_revised_invoices: {},
      showWarehouseAddress: false,
      isFullPayment: true,
      form: {
        package_id: "",
        description: "",
        carton: "",
        cbm: "",
        price_per_cbm: "",
        total: "",
        warehouse_address: "",
        additional_info: "",
      },
    };
  },
  created: function () {
    this.getDetailPackage();
  },
  mounted() {
    this.getWarehouseAddress();
    this.getDetailPackage();
    this.getInvoices(this.id);
    this.form.package_id = this.id;
  },
  methods: {
    getWarehouseAddress() {
      this.loading = true;
      const endpoint = "warehouse-address";
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.address = data.warehouse_address;
          console.log(this.address);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    getDetailPackage() {
      this.loading = true;
      const endpoint = "customer-package/detail/" + this.code;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.package_detail = data.customer_package.package_detail;
          this.customer_detail = data.customer_package.customer_detail;
          this.payment_proof = data.customer_package.payment_proof;
          this.delivery_proof = data.customer_package.delivery_proof;
          this.package_invoice = data.customer_package.package_invoice;
          this.not_match_invoice = data.customer_package.not_match_invoice;
          this.revised_invoice = data.customer_package.revised_invoice;
          this.listDetailPackage = data.customer_package;
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    changeStatusPackages(package_id, status) {
      var data = {
        package_id: package_id,
        action: status,
      };

      Vue.swal({
        title: "Are you sure?",
        text: "Your approval will be conveyed to the customer",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit Approval!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatusPackage(data);
        }
      });
    },

    updateStatusPackage(data) {
      this.loading = true;
      const endpoint = "customer-package/approval-status";
      this.$api.post(endpoint, data, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          Vue.swal(
            "Success",
            "Approval status has been successfully updated",
            "success"
          ).then(() => {
            this.getDetailPackage();
            this.showInv = true;
          });
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    submitSingleNewInvoices() {
      this.form.package_id = this.id;
      Vue.swal({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          const endpoint = "revised-invoice/single";
          this.$api.post(endpoint, this.form, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              console.log(data);
              Vue.swal("Success", "Invoice successfully added", "success").then(
                () => {
                  this.getInvoices(this.id);
                  this.resetForm();
                  this.scrollToEnd();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    getInvoices(id) {
      this.loading = true;
      const endpoint = "package-invoice/" + id;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.invoices = data.package_invoice;
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    scrollToEnd() {
      const inv = this.$refs.inv;
      inv.scrollTop = inv.scrollHeight;
    },

    sendNotifyInvoices(is_jakarta) {
      console.log(is_jakarta);
      Vue.swal({
        title: "Are you sure?",
        text: "This invoice will be sent to the customer",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Sent Invoices!",
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            package_id: this.form.package_id,
            is_jakarta: is_jakarta,
          };
          this.loading = true;
          const endpoint = "send-invoice";
          this.$api.post(endpoint, data, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              Vue.swal("Success", "Invoice sent successfully", "success").then(
                () => {
                  this.getDetailPackage();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    sendNotifyNewInvoices() {
      Vue.swal({
        title: "Are you sure?",
        text: "This invoice will be sent to the customer",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Sent Invoices!",
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            package_id: this.form.package_id,
          };
          this.loading = true;
          const endpoint = "send-revised-invoice";
          this.$api.post(endpoint, data, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              Vue.swal("Success", "Invoice sent successfully", "success").then(
                () => {
                  this.new_invoices = false;
                  this.getDetailPackage();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    changeStatusPayment(package_id, status) {
      var data = {
        package_id: package_id,
        action: status,
        warehouse_address: "",
      };
      Vue.swal({
        title: "Are you sure?",
        text: "Your approval will be conveyed to the customer",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit Approval!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (status == "approve-down-payment") {
            this.showWarehouseAddress = true;
            // this.updateStatusPayment(data);
            this.getDetailPackage();
          } else {
            this.updateStatusPayment(data);
          }
          console.log(data);
        }
      });
    },

    submitAddress(package_id, status) {
      var data = {
        package_id: package_id,
        action: status,
        warehouse_address: this.form.warehouse_address,
        additional_info: this.form.additional_info,
      };

      this.updateStatusPayment(data);
    },

    updateStatusPayment(data) {
      this.loading = true;
      if (data.status == "approve-full-payment") {
        this.isFullPayment = false;
      }
      const endpoint = "customer-package/payment-status";
      this.$api.post(endpoint, data, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.showWarehouseAddress = false;
          Vue.swal(
            "Success",
            "Payment approval has been successfully updated",
            "success"
          ).then(() => {
            this.getDetailPackage();
            this.showInv = true;
          });
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    createNewInvoices() {
      this.new_invoices = !this.new_invoices;
    },
  },
};
</script>

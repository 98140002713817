<template>
  <div
    class="d-flex justify-content-end"
    v-if="
      listDetailPackage.status_approval == 'waiting' ||
      listDetailPackage.status_approval == 'pending'
    "
  >
    <button
      class="btn btn-primary px-4 mx-1"
      v-on:click="updateStatusPackages(listDetailPackage.id, 'approve')"
    >
      Approve Packages
    </button>
    <button
      class="btn btn-warning px-4 mx-1 b-radius"
      style="color: #ffff !important"
      v-on:click="updateStatusPackages(listDetailPackage.id, 'pending')"
    >
      Pending
    </button>
    <button
      class="btn btn-danger px-4 mx-1"
      v-on:click="updateStatusPackages(listDetailPackage.id, 'reject')"
    >
      Reject
    </button>
  </div>
</template>
<script>
export default {
  props: ["listDetailPackage", "changeStatusPackages"],
  methods: {
    updateStatusPackages(id, status) {
      this.changeStatusPackages(id, status);
    },
  },
};
</script>

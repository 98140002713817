<template>
  <div
    class="d-flex justify-content-end"
    v-if="
      (listDetailPackage.status_payment == 'waiting' ||
        listDetailPackage.status_payment == 'pending') &&
      listDetailPackage.status_approval == 'approved' &&
      listDetailPackage.payment_proof.length !== 0
    "
  >
    <button
      class="btn btn-primary px-4 mx-1"
      v-on:click="
        updateStatusPayment(listDetailPackage.id, 'approve-down-payment')
      "
    >
      Approve payment
    </button>

    <button
      class="btn btn-warning px-4 mx-1 b-radius"
      style="color: #ffff !important"
      v-on:click="updateStatusPayment(listDetailPackage.id, 'pending')"
    >
      Pending
    </button>
    <button
      class="btn btn-danger px-4 mx-1"
      v-on:click="updateStatusPayment(listDetailPackage.id, 'reject')"
    >
      Reject
    </button>
  </div>
</template>
<script>
export default {
  props: ["listDetailPackage", "changeStatusPayment"],
  methods: {
    updateStatusPayment(id, status) {
      this.changeStatusPayment(id, status);
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card shadow">
        <div class="card-body">
          <h4 class="f-bold cl-primary text-left pl-3 pt-4 text-center">
            Create New Invoice
          </h4>
          <!-- <form @submit.prevent="submitSingleNewInvoices">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                    placeholder="Description"
                    class="form-control"
                    id="description"
                    rows="3"
                    v-model="form.description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="carton">Carton</label>
                  <input
                    type="number"
                    class="form-control"
                    id="carton"
                    aria-describedby="carton"
                    placeholder="Carton"
                    v-model="form.carton"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cbm">Total CBM</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cbm"
                    aria-describedby="cbm"
                    placeholder="CBM"
                    v-model="form.cbm"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="price_per_cbm">Price per CBM</label>
                  <input
                    type="text"
                    class="form-control"
                    id="price_per_cbm"
                    aria-describedby="price_per_cbm"
                    placeholder="Price per CBM"
                    v-model="form.price_per_cbm"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="total">Total Harga</label>
                  <input
                    type="text"
                    class="form-control"
                    id="total"
                    aria-describedby="total"
                    placeholder="Total"
                    v-model="form.total"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex justify-content-center">
                  <button class="btn btn-primary px-5" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form> -->

          <form @submit.prevent="submitSingleNewInvoices">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                    placeholder="Description"
                    class="form-control"
                    id="description"
                    rows="3"
                    v-model="form.description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="carton">Carton</label>
                  <input
                    type="number"
                    class="form-control"
                    id="carton"
                    aria-describedby="carton"
                    placeholder="Carton"
                    v-model="form.carton"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cbm">Total CBM</label>
                  <input
                    class="form-control"
                    id="cbm"
                    type="text"
                    aria-describedby="cbm"
                    placeholder="CBM"
                    v-model="form.cbm"
                    @keyup="totalCountCbm($event)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="price_per_cbm">Price/CBM</label>
                  <input
                    type="number"
                    class="form-control"
                    id="price_per_cbm"
                    @keyup="totalCount($event)"
                    aria-describedby="price_per_cbm"
                    placeholder="Price per CBM"
                    v-model="form.price_per_cbm"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="total">Total Harga</label>
                  <input
                    type="text"
                    class="form-control"
                    id="total"
                    aria-describedby="total"
                    placeholder="Total"
                    disabled
                    readonly
                    v-model="form.total"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex justify-content-center">
                  <button class="btn btn-primary px-5" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card shadow">
        <div class="card-body">
          <h4 class="f-bold cl-primary text-left pl-3 pt-4 text-center">
            New Invoice this packages
          </h4>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <td>Description</td>
                  <td>Carton</td>
                  <td>CBM</td>
                  <td>Price/CBM</td>
                  <td>Total</td>
                </tr>
              </thead>
              <tr v-for="(invoices, i) in new_revised_invoices" :key="i">
                <td>{{ invoices.description }}</td>
                <td>{{ invoices.carton }}</td>
                <td>{{ invoices.cbm }}</td>
                <td>{{ invoices.price_per_cbm }}</td>
                <td>{{ invoices.total }}</td>
              </tr>
            </table>
          </div>

          <div class="d-flex justify-content-end">
            <button
              class="btn btn-primary px-5"
              v-on:click="sendNotifyNewInvoices()"
            >
              Send to customer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import LoaderComponent from "../../Loader.vue";
export default {
  props: ["package_id", "sendNotifyNewInvoices"],
  // components: { LoaderComponent },
  data() {
    return {
      loading: false,
      new_revised_invoices: {},
      is_jakarta: "",
      form: {
        package_id: "",
        description: "",
        carton: "",
        cbm: 0,
        price_per_cbm: 0,
        total: "",
        warehouse_address: "",
      },
    };
  },
  mounted() {
    this.getNewInvoices(this.package_id);
  },
  methods: {
    getNewInvoices(id) {
      this.loading = true;
      const endpoint = "revised-invoice/" + id;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.new_revised_invoices = data.package_invoice;
          // this.invoices = data.package_invoice;
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    totalCount(e) {
      this.form.price_per_cbm = e.target.value;
      this.form.total =
        parseFloat(this.form.cbm) * parseFloat(this.form.price_per_cbm);
      console.log(this.form.total);
      if (this.form.price_per_cbm == 0) {
        this.form.total = 0;
      }
    },

    totalCountCbm(e) {
      this.form.cbm = e.target.value;
      this.form.total =
        parseFloat(this.form.cbm) * parseFloat(this.form.price_per_cbm);
      console.log(this.form.total);
      if (this.form.cbm == 0) {
        this.form.total = 0;
      }
    },

    submitSingleNewInvoices() {
      this.form.package_id = this.package_id;
      Vue.swal({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          const endpoint = "revised-invoice/single";
          this.$api.post(endpoint, this.form, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              console.log(data);
              Vue.swal("Success", "Invoice successfully added", "success").then(
                () => {
                  this.getNewInvoices(this.package_id);
                  this.resetForm();
                  this.scrollToEnd();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    resetForm() {
      this.form.description = "";
      this.form.carton = "";
      this.form.cbm = "";
      this.form.price_per_cbm = "";
      this.form.total = "";
    },
  },
};
</script>
